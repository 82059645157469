<template>
  <div
    class="form-group"
    :class="{ 'row align-items-center': !options.labelStacked }"
  >
    <!------------ START: Label ------------>
    <div
      v-if="showLabels"
      class="d-flex justify-content-start align-items-center flex-wrap align-self-start label-wrapper"
      :class="{ [labelColClass]: !options.labelStacked }"
    >
      <span v-if="field.label" class="flex-grow-1">
        <label :class="{ 'd-flex': field.status }" class="col-form-label">
          <span
            v-if="field.status"
            :class="field.status"
            class="circle mr-2"
          ></span>
          {{ getSnippet(field.label) }}
          <i
            v-if="field.info"
            v-b-popover.hover.top="getSnippet(field.info)"
            class="fal fa-circle-question"
          />
        </label>
        <!------------ START: Required asterisk ------------>
        <span
          v-if="
            field.required || (field.validations && field.validations.required)
          "
          class="text-danger"
        >
          *
        </span>
        <!------------ END: Required asterisk ------------>
        <!------------ START: Variables switch ------------>
        <span
          v-if="allowVariablesField"
          v-b-popover.hover.right="$t('formHelper.variablesFieldSwitch')"
          class="mx-1 align-middle cursor-pointer text-hover-primary"
          @click="toggleVariablesField"
        >
          <i
            class="fal fa-brackets-curly"
            :class="{ 'text-primary': variablesFieldActive }"
          />
        </span>
        <!------------ END: Variables switch ------------>
      </span>
      <!------------ START: Slot label append ------------>
      <span>
        <slot name="label-append"></slot>
      </span>
      <!------------ END: Slot label append ------------>

      <div v-if="field.infoText" class="w-100">
        <span
          class="info-text"
          :class="{
            'has-action': hasInfoTextAction
          }"
          @click="handleInfoTextClick"
        >
          {{ getSnippet(field.infoText) }}
        </span>
      </div>
      <div v-if="field.errorText" class="w-100 error-text">
        {{ getSnippet(field.errorText) }}
      </div>
    </div>
    <!------------ END Label ------------>

    <div
      :class="{
        [fieldColClass]: !options.labelStacked,
        [fieldColClassXl]: !options.labelStacked && !options.fullWidth
      }"
    >
      <div v-if="showSkeleton" class="w-100">
        <b-skeleton type="input"></b-skeleton>
      </div>
      <div
        :class="[
          showSkeleton ? 'invisible max-height-0' : 'd-flex flex-nowrap'
        ]"
      >
        <div
          :class="{
            'flex-grow-1 mw-100': !['checkbox', 'action'].includes(field.type)
          }"
        >
          <!------------ START: Input group-->
          <div
            v-if="inputGroup"
            ref="inputGroup"
            class="input-group"
            :class="
              appendStyleClass || prependStyleClass ? 'input-minimal' : ''
            "
          >
            <!------------ START: Copy ------------>
            <div v-if="field.copy" class="input-group-prepend">
              <span
                v-b-popover.hover.top="$t('formHelper.copy')"
                class="input-group-text cursor-pointer"
                @click="copyValue"
              >
                <i class="fal fa-copy" />
              </span>
            </div>
            <!------------ END: Copy ------------>
            <!------------ START: Prepend icon ------------>
            <div
              v-if="field.prependIcon"
              class="input-group-prepend"
              :class="prependStyleClass"
            >
              <span class="input-group-text">
                <i :class="[field.prependIcon]" />
              </span>
            </div>
            <!------------ END: Prepend icon ------------>
            <!------------ START: Prepend text ------------>
            <div
              v-if="field.prepend"
              class="input-group-prepend"
              :class="prependStyleClass"
            >
              <span class="input-group-text">{{
                getSnippet(field.prepend)
              }}</span>
            </div>
            <!------------ END: Prepend text ------------>
            <!------------ START: Slot default ------------>
            <slot></slot>
            <!------------ END: Slot default ------------>
            <!------------ START: Append text ------------>
            <div
              v-if="field.append"
              class="input-group-append"
              :class="appendStyleClass"
            >
              <span class="input-group-text">{{
                getSnippet(field.append)
              }}</span>
            </div>
            <!------------ END: Append text ------------>
            <!------------ START: Append icon ------------>
            <div
              v-if="field.appendIcon"
              class="input-group-append"
              :class="appendStyleClass"
            >
              <span class="input-group-text">
                <i :class="[field.appendIcon]" />
              </span>
            </div>
            <!------------ END: Append icon ------------>
          </div>
          <!------------ END: Input group-->
          <!------------ START: Slot default ------------>
          <slot v-else></slot>
          <!------------ END: Slot default ------------>
          <!------------ START: Slot hints ------------>
          <span class="form-text text-muted ml-1">
            <slot name="hints"></slot>
          </span>
          <!------------ END: Slot hints ------------>
        </div>
        <div
          :class="[
            field.type !== 'group' ? 'align-self-center' : 'align-self-top pt-2'
          ]"
        >
          <!------------ START: Help text ------------>
          <i
            v-if="field.help"
            v-b-popover.hover.top="helpText"
            class="fal fa-circle-info text-hover-primary ml-1"
          />
          <!------------ END: Help text ------------>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSnippet } from "@/components/Tools/FormHelper/Helper/functions";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "FieldWrapper",
  components: {},
  inject: ["options", "variablesField"],
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    inputGroup: {
      type: Boolean,
      default: false
    },
    variablesFieldActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    i18nService() {
      return i18nService;
    },
    showLabels() {
      const fieldShowLabels = this.field?.showLabels ?? true;
      const optionsShowLabels = this.options.showLabels;

      return fieldShowLabels && optionsShowLabels;
    },
    // Return final snippet for help text
    helpText: function () {
      if (!this.field.help) {
        return "";
      }
      let snippet = `${this.options.snippetPrefix}.${this.field.help}`;
      return this.$te(snippet) ? this.$t(snippet).toString() : this.field.help;
    },
    allowVariablesField: function () {
      return (
        this.options.enableVariables &&
        this.field.enableVariables !== false &&
        this.variablesField.includes(this.field.type)
      );
    },
    prependStyleClass: function () {
      return this.field.prependVariant === "minimal"
        ? "prepend-append-minimal"
        : "";
    },
    appendStyleClass: function () {
      return this.field.appendVariant === "minimal"
        ? "prepend-append-minimal"
        : "";
    },
    showSkeleton: function () {
      let loadingKey = this?.options?.loadingKey ?? "",
        loadingGroup = this?.options?.loadingGroup ?? "";

      return !!(
        !this.options.disableSkeletonLoading &&
        this.$store.getters["loadingQueue/showLoadingBar"](
          loadingKey,
          loadingGroup
        ) &&
        this.field.type !== "code"
      );
    },
    labelColClass: function () {
      return this.field.labelColClass
        ? this.field.labelColClass
        : this.options.labelColClass
          ? this.options.labelColClass
          : "col-xl-3 col-lg-3 mt-2";
    },
    fieldColClass: function () {
      return this.field.fieldColClass
        ? this.field.fieldColClass
        : this.options.fieldColClass
        ? this.options.fieldColClass
          : "col-lg-9";
    },
    fieldColClassXl: function () {
      return this.field.fieldColClassXl
        ? this.field.fieldColClassXl
        : this.options.fieldColClassXl
          ? this.options.fieldColClassXl
          : "col-xl-6";
    },
    hasInfoTextAction: function () {
      return !!(
        this.field?.infoTextAction &&
        typeof this.field?.infoTextAction === "function"
      );
    }
  },
  mounted() {},
  methods: {
    // Get text as snippet by given prefix
    getSnippet(text) {
      return getSnippet(
        text,
        this.options.snippetPrefix,
        this.options.labelData
      );
    },
    // Copy value to clipboard
    copyValue() {
      this.$parent.copyValue();
    },
    toggleVariablesField() {
      this.$emit("toggle-variables-field");
    },
    handleInfoTextClick() {
      if (!this.hasInfoTextAction) return;

      this.field.infoTextAction();
    }
  }
};
</script>

<style lang="scss" scoped>
.input-minimal {
  > .form-control {
    &:not(:last-child) {
      border-radius: 6px;
    }
  }
}

.prepend-append-minimal {
  > .input-group-text {
    background: transparent;
    border: none;
  }
}

.max-height-0 {
  max-height: 0;
}

.info-text {
  color: $color-connect-text-light;
}

.has-action {
  cursor: pointer;
  color: $color-connect-primary;
  transition: all 0.15s;

  &:hover {
    color: darken($color-connect-primary, 10%);
  }
}

.error-text {
  border: 1px solid $color-connect-error;
  background-color: #ff35543f;
  padding: 8px;
  border-radius: 8px;
}

// Override status circle vuetify styling
.circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;

  &.default {
    background-color: #dfdada !important;
  }

  &.success {
    background-color: lighten($color-connect-success, 10) !important;
  }

  &.error {
    background-color: lighten($color-connect-error, 12.5) !important;
  }
}

.form-control {
  &.is-valid {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBzdHJva2U9IiMzY2NiN2YiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0ibTUgOCAyIDIgNC00bTMuNjY3IDJBNi42NjcgNi42NjcgMCAxIDEgMS4zMzMgOGE2LjY2NyA2LjY2NyAwIDAgMSAxMy4zMzQgMFoiLz4KPC9zdmc+Cg==");
  }
}
</style>
