var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:{ 'row align-items-center': !_vm.options.labelStacked }},[(_vm.showLabels)?_c('div',{staticClass:"d-flex justify-content-start align-items-center flex-wrap align-self-start label-wrapper",class:{ [_vm.labelColClass]: !_vm.options.labelStacked }},[(_vm.field.label)?_c('span',{staticClass:"flex-grow-1"},[_c('label',{staticClass:"col-form-label",class:{ 'd-flex': _vm.field.status }},[(_vm.field.status)?_c('span',{staticClass:"circle mr-2",class:_vm.field.status}):_vm._e(),_vm._v(" "+_vm._s(_vm.getSnippet(_vm.field.label))+" "),(_vm.field.info)?_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getSnippet(_vm.field.info)),expression:"getSnippet(field.info)",modifiers:{"hover":true,"top":true}}],staticClass:"fal fa-circle-question"}):_vm._e()]),(
          _vm.field.required || (_vm.field.validations && _vm.field.validations.required)
        )?_c('span',{staticClass:"text-danger"},[_vm._v(" * ")]):_vm._e(),(_vm.allowVariablesField)?_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",value:(_vm.$t('formHelper.variablesFieldSwitch')),expression:"$t('formHelper.variablesFieldSwitch')",modifiers:{"hover":true,"right":true}}],staticClass:"mx-1 align-middle cursor-pointer text-hover-primary",on:{"click":_vm.toggleVariablesField}},[_c('i',{staticClass:"fal fa-brackets-curly",class:{ 'text-primary': _vm.variablesFieldActive }})]):_vm._e()]):_vm._e(),_c('span',[_vm._t("label-append")],2),(_vm.field.infoText)?_c('div',{staticClass:"w-100"},[_c('span',{staticClass:"info-text",class:{
          'has-action': _vm.hasInfoTextAction
        },on:{"click":_vm.handleInfoTextClick}},[_vm._v(" "+_vm._s(_vm.getSnippet(_vm.field.infoText))+" ")])]):_vm._e(),(_vm.field.errorText)?_c('div',{staticClass:"w-100 error-text"},[_vm._v(" "+_vm._s(_vm.getSnippet(_vm.field.errorText))+" ")]):_vm._e()]):_vm._e(),_c('div',{class:{
      [_vm.fieldColClass]: !_vm.options.labelStacked,
      [_vm.fieldColClassXl]: !_vm.options.labelStacked && !_vm.options.fullWidth
    }},[(_vm.showSkeleton)?_c('div',{staticClass:"w-100"},[_c('b-skeleton',{attrs:{"type":"input"}})],1):_vm._e(),_c('div',{class:[
        _vm.showSkeleton ? 'invisible max-height-0' : 'd-flex flex-nowrap'
      ]},[_c('div',{class:{
          'flex-grow-1 mw-100': !['checkbox', 'action'].includes(_vm.field.type)
        }},[(_vm.inputGroup)?_c('div',{ref:"inputGroup",staticClass:"input-group",class:_vm.appendStyleClass || _vm.prependStyleClass ? 'input-minimal' : ''},[(_vm.field.copy)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.$t('formHelper.copy')),expression:"$t('formHelper.copy')",modifiers:{"hover":true,"top":true}}],staticClass:"input-group-text cursor-pointer",on:{"click":_vm.copyValue}},[_c('i',{staticClass:"fal fa-copy"})])]):_vm._e(),(_vm.field.prependIcon)?_c('div',{staticClass:"input-group-prepend",class:_vm.prependStyleClass},[_c('span',{staticClass:"input-group-text"},[_c('i',{class:[_vm.field.prependIcon]})])]):_vm._e(),(_vm.field.prepend)?_c('div',{staticClass:"input-group-prepend",class:_vm.prependStyleClass},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.getSnippet(_vm.field.prepend)))])]):_vm._e(),_vm._t("default"),(_vm.field.append)?_c('div',{staticClass:"input-group-append",class:_vm.appendStyleClass},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.getSnippet(_vm.field.append)))])]):_vm._e(),(_vm.field.appendIcon)?_c('div',{staticClass:"input-group-append",class:_vm.appendStyleClass},[_c('span',{staticClass:"input-group-text"},[_c('i',{class:[_vm.field.appendIcon]})])]):_vm._e()],2):_vm._t("default"),_c('span',{staticClass:"form-text text-muted ml-1"},[_vm._t("hints")],2)],2),_c('div',{class:[
          _vm.field.type !== 'group' ? 'align-self-center' : 'align-self-top pt-2'
        ]},[(_vm.field.help)?_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.helpText),expression:"helpText",modifiers:{"hover":true,"top":true}}],staticClass:"fal fa-circle-info text-hover-primary ml-1"}):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }